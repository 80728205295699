/***** SOCIAL LINK STYLES *****/
.social-links {
  gap: 16px;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
}
.social-link-btn {
  height: 56px;
  width: 184px;
  min-width: 184px;
  border-radius: 100px;
  transition: opacity 300ms linear;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.social-link-btn:hover {
  opacity: 0.5;
  cursor: pointer;
}
.social-link-btn svg {
  height: 36px;
  width: 36px;
  margin-left: -8px;
  margin-right: 8px;
}
.social-link-btn i {
  font-size: 24px;
}
.email-btn {
  background-color: $accent-color;
  color: #333;
}
.sms-btn {
  background: #f00073;
  color: #fff;
}

.facebook-btn {
  background: #1877f2;
  color: #fff;
}
.twitter-btn {
  background: #000;
  color: #fff;
}
.whatsapp-btn {
  background: #25d366;
  color: #fff;
}
