/***** DONATE LINK STYLES *****/
.donate-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  > p {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 24px;
    color: #fff;
    @media only screen and (max-width: 600px) {
      line-height: 26px;
    }
  }
  i {
    font-size: 20px;
    margin-left: 8px;
  }
}
