* {
  box-sizing: border-box;
}

/***** PAGE STYLES *****/
body {
  background: #fff;
}

/***** CONTAINER STYLES *****/
.container {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}
.page.container {
  padding: 40px;
}
@media only screen and (max-width: 600px) {
  .container {
    padding: 0 24px;
  }
}

/***** DISPLAY STYLES *****/
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}

/***** POSITION STYLES *****/
.relative {
  position: relative;
}

/***** WIDTH STYLES *****/
.full-width {
  width: 100%;
}
.half-width {
  width: 50%;
}

/***** HEIGHT STYLES *****/
.full-height {
  height: 100%;
}
@media only screen and (max-width: 1040px) {
  .tablet-full-width {
    width: 100%;
  }
}

/***** FLEX STYLES *****/
.flex {
  display: flex;
}
.flex-align-center {
  align-items: center;
}
.flex-justify-space {
  justify-content: space-between;
}
.flex-justify-center {
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}
@media only screen and (max-width: 1040px) {
  .tablet-flex-column-reverse {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 600px) {
  .flex.mobile-block {
    display: block;
  }
  .mobile-flex-justify-space {
    justify-content: space-between;
  }
  .mobile-flex-column {
    flex-direction: column;
  }
  .mobile-flex-column-reverse {
    flex-direction: column-reverse;
  }
}

/***** GRID STYLES *****/
.grid {
  display: grid;
}
.align-self-center {
  align-self: center;
}

/***** OVERFLOW STYLES *****/
.overflow-hidden {
  overflow: hidden;
}

/***** MARGIN STYLES *****/
.mt-120 {
  margin-top: 120px;
}
.mb-120 {
  margin-bottom: 120px;
}
.mt-96 {
  margin-top: 96px;
}
.mt-80 {
  margin-top: 80px;
}
.mb-72 {
  margin-bottom: 76px;
}
.mb-56 {
  margin-bottom: 56px;
}
.mt-48 {
  margin-top: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mt-40 {
  margin-top: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-88 {
  margin-bottom: 88px;
}
.ml-40 {
  margin-left: 40px;
}
.mt-32 {
  margin-top: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-20 {
  margin-right: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-16 {
  margin-top: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mt-12 {
  margin-top: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mt-8 {
  margin-top: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-4 {
  margin-left: 4px;
}
.mt-4 {
  margin-top: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
@media only screen and (max-width: 600px) {
  .mobile-ml-0 {
    margin-left: 0px;
  }
  .mobile-mb-0 {
    margin-bottom: 0;
  }
  .mobile-mt-8 {
    margin-top: 8px;
  }
  .mobile-mb-8 {
    margin-bottom: 8px;
  }
  .mobile-mb-12 {
    margin-bottom: 12px;
  }
  .mobile-ml-12 {
    margin-left: 12px;
  }
  .mobile-mt-24 {
    margin-top: 24px;
  }
  .mobile-mt-48 {
    margin-top: 48px;
  }
  .mobile-mb-32 {
    margin-bottom: 32px;
  }
  .mobile-mb-48 {
    margin-bottom: 48px;
  }
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1041px) {
  .desktop-hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 561px) and (max-width: 1040px) {
  .tablet-hidden {
    display: none !important;
  }
  .tablet-visible {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .mobile-hidden {
    display: none !important;
  }
  .mobile-showing {
    display: block;
  }
}
