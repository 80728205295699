/***** CHECKOUT STYLES *****/
/*** Purchase Tickets Styles ***/
.purchase-tickets {
  background: #04a588;
  background: linear-gradient(180deg, #04a589, #1fc8aa);
  min-height: 600px;
  position: relative;
  padding: 56px 16px 32px;
  .background-text {
    font-family: ArchivoCondensed;
    text-transform: uppercase;
    position: absolute;
    top: 220px;
    right: -200px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    font-size: 172px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.1);
    @media only screen and (max-width: 600px) {
      right: -248px;
    }
  }
}
.ticket-qty-grid {
  display: flex;
  justify-content: space-between;
  width: 880px;
  max-width: 100%;
  margin: 24px auto;
  @media only screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}
.ticket-qty-option {
  width: 200px;
  height: 128px;
  background: #fff;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: "all 300ms linear";
  &::before {
    content: "";
    height: 24px;
    width: 24px;
    background: #04a588;
    border-radius: 100%;
    position: absolute;
    top: -12px;
    left: 50%;
    margin-left: -12px;
  }
  &::after {
    content: "";
    height: 24px;
    width: 24px;
    background: #04a588;
    border-radius: 100%;
    position: absolute;
    bottom: -12px;
    left: 50%;
    margin-left: -12px;
  }
  &:hover {
    background: #d1f8f1;
  }
  &.active {
    background: #25ebc8;
  }
  @media only screen and (max-width: 600px) {
    width: auto;
  }
}
.ticket-qty {
  color: $primary-color;
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 8px;
  text-align: center;
  .active & {
    color: #333;
  }
}
.ticket-cost {
  font-weight: 800;
  font-size: 18px;
}
.custom-ticket-qty-input {
  display: flex;
  height: 56px;
  button {
    border: 0;
    height: 56px;
    background: #fff;
    width: 44px;
    background: #fff;
    box-shadow: none;
    border-radius: 0px;
    color: #a9abae;
    font-weight: 900;
    font-family: "Varela Round";
    cursor: pointer;
    transition: all 300ms linear;
    &:hover {
      color: rgba(169, 171, 174, 0.7);
    }
    &:first-child {
      border-radius: 3px 0 0 3px;
      font-size: 40px;
      line-height: 40px;
      border-right: 1px solid #e7e6f2;
    }
    &:last-child {
      border-radius: 0 3px 3px 0;
      font-size: 32px;
      line-height: 56px;
      border-left: 1px solid #e7e6f2;
    }
  }
  .custom-input {
    width: 64px;
    height: 56px;
    border: 0;
    background: #fff;
    box-shadow: none;
    border-radius: 0px;
  }
}
/*** Checkout Summary Styles ***/
.checkout-summary {
  padding: 48px 16px 0;
}
.checkout-summary-items {
  width: 304px;
  max-width: 100%;
  margin: 0 auto 48px;
}
.checkout-summary-divider {
  height: 2px;
  background: rgba(0, 0, 0, 0.1);
}
/*** Checkout Form Styles ***/
.checkout-grid {
  grid-template-columns: 1fr 1fr;
  display: grid;
  gap: 32px;
  > div {
    display: grid;
    grid-template-columns: 160px 1fr;
  }
  label {
    font-family: Inter;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    margin-right: 8px;
    line-height: 1.2;
    span {
      text-transform: lowercase;
      font-size: 12px;
      display: block;
      max-width: 104px;
      line-height: 1.3;
      @media only screen and (max-width: 600px) {
        display: inline-flex;
        max-width: unset;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}
.payment-methods-grid {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  row-gap: 16px;
  column-gap: 8px;
  width: 100%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
}
.paypal-buttons {
  width: 100%;
  max-width: 368px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    width: 180px;
  }
}
/* Apple Pay Button Styles */
.apple-pay-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  appearance: -apple-pay-button;
  -apple-pay-button-type: check-out;
  width: 100%;
  max-width: 260px;
  height: 48px;
}
.apple-pay-button-black {
  -apple-pay-button-style: black;
}
.apple-pay-button-white {
  -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}
/* Payment Methods */
.pay-btn {
  height: 48px;
  width: 180px;
  border: 1px solid #888686;
  border-radius: 3px;
}
/* Billing Info */
.billing-info {
  background: #f2f2f2;
  padding: 32px 40px;
  margin: 0 auto;
  width: 1280px;
  max-width: calc(100% - 48px);
  border-radius: 3px;
  @media only screen and (max-width: 600px) {
    padding: 24px;
  }
}
.custom-stripe-element {
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 100%;
  }
}
