input,
select,
textarea {
  font-size: 15px;
  font-family: Inter;
}
/***** CUSTOM INPUT STYLES *****/
.custom-input {
  border: 1px solid #888686;
  border-radius: 3px;
  padding: 0 8px;
  height: 48px;
  background: transparent;
  transition: border 300ms linear;
  width: 100%;
}
.custom-input:focus {
  border-color: $primary-color;
  outline: none;
}
.custom-input::placeholder {
  color: rgba(0, 0, 0, 0.24);
}
.custom-input.error {
  border-width: 2px;
  border-color: $error-color;
}
.custom-input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.custom-input select {
  width: 100%;
  border: none;
  background: transparent;
}
input.custom-input:read-only {
  border: none;
  padding: 0;
}
.custom-input.percentage-input {
  background: url("/images/percentage.png");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 96% center;
}
label.error + .custom-input {
  border: 1px solid $error-color;
}

/***** CUSTOM SELECT STYLES *****/
.custom-select {
  border: 1px solid #888686;
  background: #fff;
  height: 48px;
  background: transparent;
  transition: border 300ms linear;
  width: 100%;
  padding: 0 4px;
  position: relative;
  border-radius: 3px;
}
.custom-select select {
  height: 100%;
  background: transparent;
  border: none;
  width: 100%;
  outline: none;
}
.clear-select {
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
}
.custom-select.error {
  border-width: 2px;
  border-color: $error-color;
}
.custom-select option:first-child {
  pointer-events: none;
}

/***** PHONE INPUT STYLES ****/
.phone-input {
  border: 1px solid #888686;
  border-radius: 3px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 14px;
}
.phone-input.error {
  border: 2px solid $error-color;
}
.phone-input .MuiSelect-select.MuiSelect-select {
  padding-right: 32px;
  padding-top: 12px;
  border-bottom: none;
}
.phone-input .MuiInput-underline:before {
  border-bottom: none;
}

.phone-input .MuiInput-underline:after {
  border-bottom: none;
}
/***** CUSTOM CHECKBOX STYLES *****/
.custom-checkbox[type="checkbox"] {
  display: none;
}
.custom-checkbox[type="checkbox"] + label {
  position: relative;
  display: flex;
  align-items: center;
  min-width: auto;
  width: auto;
  margin-right: 0;
  opacity: 1;
  text-transform: initial;
}
.custom-checkbox[type="checkbox"] + label:before {
  content: "";
  border: 1px solid;
  height: 16px;
  width: 16px;
  min-width: 16px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: bottom;
  transition: all 300ms linear;
}
.custom-checkbox[type="checkbox"]:checked + label:before {
  height: 16px;
  width: 16px;
  min-width: 16px;
  border-radius: 4px;
  display: inline-block;
  background: $primary-color;
  border: 1px solid $primary-color;
}
.custom-checkbox[type="checkbox"]:checked + label:after {
  content: "";
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  height: 10px;
  width: 4px;
  display: inline-block;
  transform: rotate(45deg);
  position: absolute;
  left: 6px;
  top: 1px;
  background: transparent;
  @media only screen and (max-width: 600px) {
    top: 50%;
    margin-top: -8px;
  }
}
.custom-checkbox[type="checkbox"] + label.error-text {
  color: $error-color;
  font-weight: 600;
}
.custom-checkbox[type="checkbox"] + label.error-text:before {
  border-color: $error-color;
  border-width: 2px;
}

/***** ERROR MESSAGES STYLES *****/
.error-message {
  font-size: 13px;
  color: $error-color;
  position: absolute;
}
.error-box {
  background: $error-color;
  border-radius: 1px;
  padding: 8px;
  margin-top: 4px;
  display: inline-flex;
}
