/***** CURSOR STYLES *****/
.pointer {
  cursor: pointer;
}

/***** IMAGE STYLES *****/
img {
  max-width: 100%;
}

/***** FULL PAGE STATE STYLES *****/
.full-page-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 242px);
}
.full-page-state img {
  height: 240px;
  margin-bottom: 40px;
}

/***** LINE HEIGHT STYLES *****/
.line-height-double {
  line-height: 1.5;
}
