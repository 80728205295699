/***** HEADER STYLES *****/
.header {
  padding: 48px 24px 40px;
  position: relative;
  > * {
    position: relative;
  }
  > .background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    .video-overlay {
      background: rgba(51, 51, 51, 0.4);
      mix-blend-mode: soft-light;
      height: 100%;
      width: 100vw;
      position: absolute;
      top: 0;
    }
    .video-overlay-dark {
      background: #333;
      mix-blend-mode: hard-light;
    }
  }
  .background-text {
    font-family: ArchivoCondensed;
    font-size: 200px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    top: -200px;
    left: 50%;
    -webkit-transform: rotate(90deg);
    transform: translateX(-50%) rotate(90deg);
    font-size: 172px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.2);
  }
}
.header-tint-dark {
  background: url("/images/gradientBackground.jpg") rgba(51, 51, 51, 0.4);
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
}
.header-title {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    gap: 16px;
  }
}
.bh {
  font-family: Heebo;
  position: absolute;
  right: 48px;
  top: 48px;
  font-size: 16px;
  @media only screen and (max-width: 600px) {
    position: unset;
  }
}
.team-name-banner {
  background: $accent-color;
  min-height: 32px;
  padding: 0 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 18px;
  border-radius: 100px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    padding: 8px 32px;
  }
}
.header-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  flex-direction: column;
  img {
    max-height: 216px;
  }
  @media only screen and (max-width: 600px) {
    img {
      max-height: 154px;
      max-width: 80%;
    }
    .fs-50 {
      font-size: 30px;
      line-height: 33px;
    }
  }
}
.raffle-back-to-home {
  position: absolute;
  top: 80px;
  left: 48px;
  color: #fff;
  cursor: pointer;
  font-family: Inter;
  @media only screen and (max-width: 600px) {
    top: 44px;
    left: 16px;
  }
}
.sticky-purchase-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}