/***** REACT MODAL OVERLAY STYLES *****/
.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6) !important;
  z-index: 101;
}

.modal-container {
  overflow: auto;
  max-height: 80vh;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 2px 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
  outline: none;
  background: #fff;
}

.modal-background-no-scroll {
  overflow: hidden;
}

/***** TERMS AND CONDITIONS MODAL *****/
.terms-and-conditions-modal {
  @extend .modal-container;
  border-radius: 3px;
  padding: 24px;
  width: 680px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.04);
  position: relative;
  @media only screen and (max-width: 600px) {
    width: 80%;
    z-index: 999;
  }
}
.terms-and-conditions-box {
  background: transparent;
  height: 240px;
  border-radius: 3px;
  overflow: auto;
}
.terms-and-conditions-modal .modal-close {
  text-align: right;
}
.terms-and-conditions-box::-webkit-scrollbar {
  background: black;
}
.terms-and-conditions-box::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

/***** PRIVACY POLICY MODAL *****/
.privacy-policy ul {
  list-style: disc outside;
}
.privacy-policy li {
  margin-left: 40px;
}
.privacy-policy p {
  margin-bottom: 16px;
}
.privacy-policy h1 {
  text-decoration-line: underline;
  margin-bottom: 16px;
}
.privacy-policy h2 {
  margin-bottom: 16px;
}
