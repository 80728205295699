/***** PRIZE GRID STYLES *****/
.prizes-grid {
  display: flex;
  position: relative;
  width: 872px;
  gap: 36px 24px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    gap: 16px;
  }
}

/***** PRIZE CARD STYLES *****/
.prize {
  display: flex;
  justify-content: center;
  padding: 24px 24px 24px 72px;
  flex-direction: column;
  min-width: 272px;
  width: min-content;
  max-width: 100%;
  background: #fff;
  border-radius: 8px;
  position: relative;
  height: 168px;
  color: #333;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 144px;
    padding: 8px 8px 8px 64px;
  }
}
.prize:nth-child(1),
.prize:nth-child(2) {
  width: 424px;
  max-width: 100%;
  height: 192px;
  padding: 24px 24px 24px 80px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 8px 8px 8px 64px;
    height: 144px;
  }
}
.prize-place {
  font-size: 18px;
  text-transform: uppercase;
  position: absolute;
  left: -60px;
  bottom: 60px;
  transform: rotate(-90deg);
  background-color: $accent-color;
  border-radius: 8px 8px 0 0;
  height: 48px;
  width: 168px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary-color;
  @media only screen and (max-width: 600px) {
    width: 144px;
    bottom: 48px;
    left: -48px;
  }
}
.prize:nth-child(1) .prize-place,
.prize:nth-child(2) .prize-place {
  width: 192px;
  left: -72px;
  bottom: 72px;
  @media only screen and (max-width: 600px) {
    width: 144px;
    left: -48px;
    bottom: 48px;
  }
}
.prize-title {
  font-family: ArchivoCondensed;
  font-size: 65px;
  line-height: 60px;
  @media only screen and (max-width: 600px) {
    font-size: 50px;
    line-height: 45px;
  }
}
.prize-winner-amount {
  font-size: 18px;
  margin-top: 16px;
  text-transform: uppercase;
  color: #b5b6ba;
  font-weight: 500;
  @media only screen and (max-width: 600px) {
    margin-top: 8px;
  }
}
.prize-winner-name {
  font-size: 18px;
  font-weight: 700;
  margin-top: 8px;
  color: $primary-color;
  @media only screen and (max-width: 600px) {
   font-size: 16px;
   line-height: 18px;
  }
}
.prize-winner-name span {
  display: inline-block;
  white-space: pre-wrap;
}
.prize-img {
  height: 88px;
  width: auto;
  position: absolute;
  bottom: -16px;
  right: 24px;
  transition: all 300ms linear;
}
.prize-img:hover {
  transform: scale(1.2);
}
.prize:nth-child(1) .prize-img,
.prize:nth-child(2) .prize-img {
  height: 120px;
  width: auto;
  bottom: 16px;
  right: 24px;
  @media only screen and (max-width: 600px) {
    transform: scale(0.7);
    bottom: 0;
  }
}

.prize-description {
  display: none;
  text-transform: uppercase;
  font-size: 16px;
}
.cash-prize-disclaimer-asterisk {
  position: absolute;
  bottom: -22px;
  left: 6px;
  font-size: 14px;
}
.cash-prize-disclaimer {
  font-size: 12px;
  margin-top: 16px;
}
