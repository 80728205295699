/***** BUTTON STYLES *****/
.btn {
  font-family: Archivo;
  text-transform: uppercase;
  font-size: 14px;
  transition:
    all 300ms linear,
    color 300ms linear;
  white-space: nowrap;
  border: none;
  transition: all 300ms linear;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn:hover {
  transform: scale(1.05);
}
.btn-rounded {
  width: 264px;
  height: 56px;
  border-radius: 100px;
  background-color: $accent-color;
  color: #000;
  font-weight: 700;
  letter-spacing: 1.5px;
}
.btn-rounded-outline {
  width: 200px;
  height: 56px;
  background-color: transparent;
  border: 1px solid $accent-color;
  color: $accent-color;
}
.btn-squared {
  background: #25ebc8;
  color: #333;
  height: 40px;
  width: 168px;
  font-family: Inter;
}
.btn-text {
  color: $primary-color;
  font-weight: 700;
  letter-spacing: 2px;
  background: transparent;
}
