@font-face {
  font-family: ArchivoCondensed;
  src: url(/fonts/Archivo_Condensed-ExtraBold.ttf);
}

body {
  font-family: Archivo;
  color: #333;
  font-size: 14px;
}
a {
  text-decoration: none;
  color: inherit;
}

/***** FONT WEIGHT STYLES *****/
.fw-900 {
  font-weight: 900;
}
.fw-800 {
  font-weight: 800;
}
.fw-700 {
  font-weight: 700;
}
.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.fw-300 {
  font-weight: 300;
}

/***** FONT SIZE STYLES *****/
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
  @media only screen and (max-width: 600px) {
    &.mobile-fs-16 {
      font-size: 16px;
    }
  }
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-28 {
  font-size: 28px;
}
.fs-30 {
  font-size: 30px;
}
.fs-32 {
  font-size: 32px;
}
.fs-45 {
  font-size: 45px;
  @media only screen and (max-width: 600px) {
    font-size: 32px;
  }
}
.fs-50 {
  font-size: 50px;
  @media only screen and (max-width: 600px) {
    font-size: 36px;
  }
}
.fs-100 {
  font-size: 100px;
  @media only screen and (max-width: 600px) {
    font-size: 70px;
  }
}

/***** COLOR STYLES *****/
.brand-color-text {
  color: $primary-color;
}
.accent-color-text {
  color: $accent-color;
}
.accent-text {
  color: #828282;
}
.error-text {
  color: $error-color;
}
.light-text {
  color: #fff;
}

/***** TEXT TRANSFORM STYLES *****/
.uppercase-text {
  text-transform: uppercase;
}
.italic-text {
  font-style: italic;
}

/***** TEXT ALIGN STYLES *****/
.text-center {
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .mobile-text-center {
    text-align: center;
  }
}

/***** TEXT DECORATION STYLES *****/
.underline-text {
  text-decoration: underline;
}

/***** WHITESPACE STYLES *****/
.no-wrap {
  white-space: nowrap;
}
