.raffle-goal-container {
  padding: 80px 16px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
  }
}
.raffle-goal {
  width: 880px;
  max-width: 100%;
}
.percentage-bar {
  width: 880px;
  max-width: 100%;
  display: flex;
  height: 56px;
  border-radius: 6px;
  margin: 0 auto;
  &.small {
    height: 24px;
  }
}
.percentage-bar .bar {
  width: 100%;
  opacity: 0.5;
  border-radius: 50px;
  background: #d9d9d9;
  box-shadow: inset 0px 2px 5px #00000026;
}
.percentage-bar .filled-in {
  position: absolute;
  width: 20%;
  height: 100%;
  top: 0;
  border-radius: 50px;
  background: linear-gradient(
    90deg,
    rgb(49.04, 202.94, 175.46) 44.5%,
    rgb(28.05, 192.31, 162.98) 72.5%,
    rgb(145.22, 94.75, 184.88) 100%
  );
}
.percentage-bar .filled-in.bonus {
  background: $accent-color;
  border-radius: 50px;
}
.percentage-bar .filled-in.not-complete {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.raffle-goal-details {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-top: 16px;
  p {
    font-size: 18;
    color: #828282;
    text-transform: uppercase;
  }
}
