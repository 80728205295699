.countdown-timer {
  height: 120px;
  background: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Archivo Black";
  font-size: 30px;
  span {
    font-family: Archivo;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 600px) {
    height: auto;
    padding: 16px;
    display: block;
    text-align: center;
    font-size: 18px;
    p:first-child {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}
.buy-now-link {
  color: #fff;
  font-family: "Archivo Black";
  font-size: 30px;
  text-transform: uppercase;
  margin-left: 32px;
  @media only screen and (max-width: 600px) {
    font-size: 18px;
    margin-left: 0;
  }
}
