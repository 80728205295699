/***** CAMPUS SEARCH STYLES *****/
.campus-search {
  background: linear-gradient(
    180deg,
    rgb(4.12, 164.69, 136.01) 6.5%,
    rgb(29.8, 198.69, 168.82) 64%
  );
  padding: 80px 40px 104px;
  .search-input {
    width: 880px;
    max-width: 100%;
    margin: 0 auto;
    border: 1px solid rgba(181, 182, 186, 1);
    background: #fff;
    color: rgba(130, 130, 130, 1);
    height: 88px;
    padding: 0 48px;
    @media only screen and (max-width: 600px) {
      padding: 0 24px;
      height: 64px;
    }
  }
  @media only screen and (max-width: 600px) {
    background: linear-gradient(179.36deg, #04a588 4.17%, #1ec7a9 112.07%);
    color: #333;
    padding: 40px 24px 56px;
  }
}
