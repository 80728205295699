/***** FAQ PAGE STYLES *****/
.faqs-page {
  background: $accent-color;
  padding: 48px 0;
}
.faqs {
  width: 940px;
  max-width: 100%;
  border-radius: 10px;
  background: #fff;
  margin: 48px auto;
  padding: 0 80px;
  @media only screen and (max-width: 1040px) {
    padding: 0;
  }
}
.faq-card {
  padding: 40px;
  .fs-22 {
    line-height: 28px;
  }
  .fs-18 {
    line-height: 30px;
  }
}
.faq-card:not(:last-child) {
  border-bottom: 1px solid #d9dbe9;
}
